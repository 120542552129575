import { Component, OnInit } from '@angular/core';
import {PortalConfigService} from '../../services/portal-config.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {

  constructor(
    public portalConfig: PortalConfigService,
  ) { }

  ngOnInit(): void {
  }

}
