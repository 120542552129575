import {Component, OnInit} from '@angular/core';
import {User} from '../../models/fasten/user';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-auth-signin',
  templateUrl: './auth-signin.component.html',
  styleUrls: ['./auth-signin.component.scss']
})
export class AuthSigninComponent implements OnInit {
  loading: boolean = false

  submitted: boolean = false
  existingUser: User = new User()
  errorMsg: string = ""

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {}

  signinSubmit(){
    this.submitted = true;
    this.loading = true

    this.authService.Signin(this.existingUser.email!, this.existingUser.password!)
      .then(() => {
        this.loading = false
        this.router.navigateByUrl('/dashboard')
      })
      .catch((err)=>{
        this.loading = false
        if(err?.name){
          this.errorMsg = "email or password is incorrect"
        } else{
          this.errorMsg = "an unknown error occurred during sign-in"
        }
        // const toastNotification = new ToastNotification()
        // toastNotification.type = ToastType.Error
        // toastNotification.message = this.errorMsg
        // this.toastService.show(toastNotification)
      })
  }
}
