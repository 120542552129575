<div class="mg-t-20 container ">


  <div class="mg-b-20">
    <div>
      <h2>{{portalConfig.config$?.user?.name}}</h2>
      <p class="az-dashboard-text">{{portalConfig.config$?.user?.email}}</p>
    </div>
  </div>


  <div class="row">
    <div class="col-md-10 col-lg-8 col-xl-6">
      <div class="card mg-t-20">
        <h5 class="card-header d-flex justify-content-between align-items-center">
          Update Password
        </h5>

        <div class="card-body">
          <p>Use this form to change the password you use to login to your account</p>

          <div class="form-group">
            <label class="az-content-label tx-11 tx-medium tx-gray-600">Old Password</label>
            <input type="text" class="form-control" required="required">
          </div><!-- form-group -->

          <div class="form-group">
            <label class="az-content-label tx-11 tx-medium tx-gray-600">New Password</label>
            <input type="text" class="form-control" required="required">
          </div><!-- form-group -->

          <div class="form-group">
            <label class="az-content-label tx-11 tx-medium tx-gray-600">Confirm new Password</label>
            <input type="text" class="form-control" required="required">
          </div><!-- form-group -->

          <button disabled class="btn btn-az-primary btn-block">Save New Password</button>

        </div>

      </div>
    </div><!-- col -->


    <div class="col-md-10 col-lg-8 col-xl-6">

      <div class="card mg-t-20">
        <h5 class="card-header d-flex justify-content-between align-items-center">
          Update Email Address
        </h5>

        <div class="card-body">
          <p>Use this form to change the email address associated with your account.</p>
          <div class="form-group">
            <label class="az-content-label tx-11 tx-medium tx-gray-600">Current Email Address</label>
            <input type="text" class="form-control" required="required" readonly value="{{portalConfig.config$.user?.email}}">
          </div><!-- form-group -->

          <div class="form-group">
            <label class="az-content-label tx-11 tx-medium tx-gray-600">New Email Address</label>
            <input type="text" class="form-control" required="required">
          </div><!-- form-group -->

          <div class="form-group">
            <label class="az-content-label tx-11 tx-medium tx-gray-600">Verify Password</label>
            <input type="text" class="form-control" required="required">
          </div><!-- form-group -->

          <button disabled class="btn btn-az-primary btn-block">Update Email Address</button>
        </div>
      </div>
    </div><!-- col -->
  </div>

</div>
