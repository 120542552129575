<div class="az-signin-wrapper">
  <div class="az-card-signin" [ngStyle]="{'height' : '560px' }">
    <img src="assets/images/banner-transparent-trim.png" alt="fasten" class="az-img-logo">

    <div class="az-signin-header">
      <h2>Welcome back!</h2>
      <h4 *ngIf="!loading">Please sign in to continue</h4>

      <form (ngSubmit)="signinSubmit()" #userForm="ngForm">
        <div class="form-group">
          <label>Email</label>
          <input [(ngModel)]="existingUser.email" name="email" #email="ngModel" required email minlength="4" type="text" class="form-control" placeholder="Enter your username">

          <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger">
            <div *ngIf="email.errors?.['required']">
              Email is required.
            </div>
            <div *ngIf="email.errors?.['minlength']">
              Email must be at least 4 characters long.
            </div>
            <div *ngIf="email.errors?.['email']">
              Email must be a valid email address.
            </div>
          </div>
        </div><!-- form-group -->
        <div class="form-group">
          <label>Password</label>
          <input [(ngModel)]="existingUser.password" name="password" #password="ngModel" required minlength="8" type="password" class="form-control" placeholder="Enter your password">

          <div *ngIf="password.invalid && (password.dirty || password.touched)" class="alert alert-danger">
            <div *ngIf="password.errors?.['required']">
              Password is required.
            </div>
            <div *ngIf="password.errors?.['minlength']">
              Password must be at least 8 characters long.
            </div>
          </div>
        </div><!-- form-group -->
        <button [disabled]="!userForm.form.valid || loading" type="submit" class="btn btn-az-primary btn-block">
          Sign In
          <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>

        <div *ngIf="errorMsg" class="alert alert-danger mt-3" role="alert">
          <strong>Error</strong> {{errorMsg}}
        </div>
      </form>

    </div><!-- az-signin-header -->
    <div class="az-signin-footer">
      <p><a routerLink="/auth/reset/request">Forgot password?</a></p>
      <p>Don't have an account? <a routerLink="/auth/signup">Create an Account</a></p>
    </div><!-- az-signin-footer -->
  </div><!-- az-card-signin -->
</div><!-- az-signin-wrapper -->
